 


.job-row-first::after {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    width: 50%;
    height: calc(100% + 2px);
    border-bottom-left-radius: 20px;
    border: 2px solid var(--job-display-border-color);
    border-right: none;
    border-top: none;

}

.job-row-first::before,
.job-row-even::before ,
.job-row-even-last::before
{

    position: absolute;
    content: "";
    width: 2em;
    height: 2em;
    background-color: var(--job-display-border-color);
    border-radius: 50%;
    top: 50%;
    left:  0 ;
    transform: translateX(-50%) translateY(-50%);

}

.job-row-odd::before ,
.job-row-odd-last::before
{
    position: absolute;
    content: "";
    width: 2em;
    height: 2em;
    background-color: var(--job-display-border-color);
    border-radius: 50%;
    top: 50%;
    left:  100% ;
    transform: translateX(-50%) translateY(-50%);
}

.job-row-even::after {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    width: 50%;
    height: calc(100% + 2px);
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border: 2px solid var(--job-display-border-color);
    border-right: none;
    border-top: 2px solid var(--job-display-border-color);;


    
}



.job-row-odd::after {
    
    content: "";
    position: absolute;
    top: -1px;
    left: 50%;
    width: 50%;
    height: calc(100% + 2px);
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border: 2px solid var(--job-display-border-color);
    border-left: none;
    border-top: 2px solid var(--job-display-border-color);;
    
}

.job-row-even-last::after {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    width: 50%;
    height: calc(100% + 2px);
    border-bottom-left-radius: 0px;
    border-top-left-radius: 20px;
    border: 2px solid var(--job-display-border-color);
    border-right: none;
    border-top: 2px solid var(--job-display-border-color);;
    border-bottom: none;
}

.job-row-odd-last::after {
    content: "";
    position: absolute;
    top: -1px;
    left: 50%;
    width: 50%;
    height: calc(100% + 2px);
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border: 2px solid var(--job-display-border-color);
    border-left: none;
    border-top: 2px solid var(--job-display-border-color);;
}
